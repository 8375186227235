body{
    font-size: 13px;
    background: #cccc;
    
}

label{
    font-weight: bold;
}


.ml-5{
    margin-left: 5px;
}

.ml-15{
    margin-left: 15px;
}
.mt-5{
    margin-top: 5px;
}
.mt-10{
    margin-top: 10px;
}

.mt-15{
    margin-top: 15px;
}

.mt-20{
    margin-top: 20px;
}

.mr-15{
    margin-right: 15px;
}
.box-white{
    background-color: white;
    padding: 15px;
    margin-top: 10px;
    -webkit-border-radius: 15px;
-moz-border-radius: 15px;
border-radius: 15px;
}
.bold{
    font-weight: bold;
}
.bold-500{
    font-weight: 500;
}

.color-compra{
    color:#108915 !important
}
.color-venda{
    color:#f35757 !important
}